/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
@font-face {
    font-family: lgsmart;
    src: url(../public/fonts/LG_Smart_Regular.woff2);
  }

body{
    margin:0;
    padding:0;
    font-family:lgsmart !important;
    background:#f0ece4 !important;
}

.mainhead{
    background: #fff;
        color: #000;
        box-shadow: 0px 0px 7px 0px #e1dcdc;
        padding: 5px 15px 0 15px;
        display: flex;
        flex-direction: row;
        margin: 10px 20px;
        border-radius: 10px;
        position:sticky;
        top:0;
        z-index:999999;
}

.mainhead .logo{
    width:205px;
    height:93px;
    display:flex;
    align-items: center;
}

.mainhead .logo img{
    height:60px;
}

.navi_bar{
    width:calc(100% - 150px);
    display:flex;
    flex-direction: column;
}

.top_nav{
    border-bottom: 1px solid #eee;
    flex-direction: row-reverse !important;
    height:42px;
    justify-content: space-between;
}

.bottom_nav{
    height:100%;
    justify-content: space-between;
}

.top_nav a{
    text-decoration:none;
    color:#cc0041;
    border:none;
    padding:0 15px;
    border-radius:10px;
    transition:0.2s;
    
}

.top_nav a:hover{
    color:#000;
    border:none;
    background:none;
    transition:0.2s;
}

.top-ko-links {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    height:42px;
}

.top-ko-links a {
    border: thin solid #cc0041;
    border-radius: 6px;
    color: #cc0041;
    text-decoration: none;
    transition: .2s;
    padding: 0 10px;
    margin-right: 10px;
    font-size: 12px;
}

.top-ko-links a:hover {
    background: #cc0041;
    border: thin solid #cc0041;
    color: #fff;
    transition: .2s;
}

.top-ko-socials{
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    padding: 2px 15px;
}

.top_nav, .bottom_nav{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
}

.main_menu{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    height:100%;
}

.footer_categories_column .ul-sub li a{
    color:#fff;
    text-decoration:none;
}

.direct-menu{
    padding:0 !important;
}

.desax{
    opacity:0;
    animation:navappears 1s forwards;
}

@keyframes navappears{
    from{
        opacity:0;
        transition:0.5s;
    }
    to{
        opacity:1;
        transition:0.5s;
    }
}

.icon_menu{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    height:100%;
}

.bottom_nav .main_menu a, .dropdown_menu{
    display: flex;
    align-items: center;
    padding:0 20px;
    height:100%;
    text-decoration:none;
    color:#000;
    font-size:14px;
    font-weight:bold;
    letter-spacing: 0.5px;
}

.sublayers a {
    height: auto !important;
}

.bottom_nav .main_menu a:hover, .dropdown_menu:hover{
    background: #cc0041;
    color:#fff;
}

.bottom_nav .icon_menu a{
    display: flex;
    align-items: center;
    margin-right:20px;
    padding:0 10px;
    height:100%;
    text-decoration:none;
    color:#000;
    font-size:14px;
    font-weight:bold;
}

.bottom_nav .icon_menu a:hover{
    color:#cc0041;
}

.login-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 20px;
}

.login-container-boxes{
    padding:20px;
    width:49.5%;
}

.login-container-sub-boxes{
    background: #fff;
    border-radius: 10px;
    padding:20px;
    display:flex;
    flex-direction: column;
}

.login-container h1{
    font-weight:bold;
}

.required-section{
    color:#cc0041;
}

.lg-input{
        border-radius: 10px;
        padding: 10px;
        margin: 5px 0;
        border:solid thin #b1b1b1;
        font-weight:bold;
}

.lg-input::placeholder{
    font-weight:400;
}

.input-field-title{
    font-weight:bold;
}

.lg-input-remember-me-and-forgot-password{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:10px;
}

.member-box-divide-row{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.login-member-box-li {
    border-top:solid thin #d6d6d6;
    padding:10px 0;
}

.login-member-box-li:first-child{
    border-top:none;
}

.member-box-divide-column{
    display:flex;
    flex-direction: column;
}

.member-box-title{
    font-size:30px;
}

.member-box-desc{
    font-size:16px;
}

.login-member-box-list{
    padding-left:0 !important;
}

.lg-checkbox{
    margin-right:5px;
    width:15px;
    height:15px;
}

.forgot-password{
    font-weight:bold;
    text-decoration:underline;
}

input[type="checkbox"]:checked {
    background-color: red;
}

.login-signup-register-product {
    display: none;
    flex-direction: column;
    background: #fff;
    position: absolute;
    z-index: 9;
    padding: 10px 20px;
    margin-left: -80px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 2px 5px #b5b5b5;
    margin-top:120px;
}

.login-btn-right-top{
    display: flex;
        align-items: center;
        margin-right: 20px;
        padding: 0 10px;
        height: 100%;
        text-decoration: none;
        color: #000;
        font-size: 14px;
        font-weight: bold;
        
}

.login-btn-right-top:hover  .login-signup-register-product{
    display:flex;
    
}

.login-signup-register-product span a{
    font-size:16px !important; 
    font-weight:normal !important;
    padding:0 !important;
}

.login-signup-register-product span a:hover {
    text-decoration:underline;
}

/* search starts */
.overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}

.overlay-content {
    position: relative;
    top: 46%;
    width: 80%;
    text-align: center;
    margin-top: 30px;
    margin: auto;
}

.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    cursor: pointer;
    color: white;
}

.overlay .closebtn:hover {
    color: #ccc;
}

.overlay input[type=text] {
    padding: 15px;
    font-size: 17px;
    border: none;
    float: left;
    width: 80%;
    background: white;
}

.overlay input[type=text]:hover {
    background: #f1f1f1;
}

.overlay button {
    float: left;
    width: 20%;
    padding: 15px;
    background: #ddd;
    font-size: 17px;
    border: none;
    cursor: pointer;
}

.overlay button:hover {
    background: #bbb;
}
/* search ends */

.section-for-product-desc{
    padding:10px 0;
}

.single-section-for-product-desc{
    margin:20px 0;
}

.single-section-for-product-desc-img{
    border-radius:10px;
    width:100%;
}

/* mega menu starts */
.menu-content {
    display: none;
    position: absolute;
    background-color: #cc0041;
    width: 100%;
    left: 0;
    box-shadow: 1px 3px 16px 0px rgba(0,0,0,0.2);
    z-index: 99999;
    color:#fff;
    top:98px;
    overflow: hidden;
    padding:0 60px;
    margin: 0;
    border-radius: 0 0 10px 10px;
    max-width: 100%;
}

.dropdown_menu:hover .menu-content {
    display: block;
}

.right-icon-menus-svg{
    height:100%;
}

.dynamic-menu-contents{
    width:100%;
    display: flex;
        flex-wrap: wrap;
}

.dynamic-image-contents{
    width:40%;
    min-width:40%;
    max-width:40%;
}

.dynamic-image-contents-2{
    width:30%;
    min-width:30%;
    max-width:30%;
}

.layers{
    width:100%;
    left:0;
    display:flex;
    flex-direction:row;
}

.sublayers{
    float:left;
    padding:25px;
}

.sublayers h5{
    margin-top:10px;
}

.sublayers-for-img{
    width: 50%;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        height: 100%;
}
.sublayers-for-img-2{
    width: 100%;
}

.sublayers-for-2menu-width{
    width:20%;
}
.sublayers-for-3menu-width{
    width:33%;
}
.sublayers-for-2menu-width-image{
    width:25%;
    display: flex;
    align-items: center;
}
.lg-form-address-divide{
    display:flex;
    column-gap: 20px;
    flex-direction: row;
    justify-content: space-between;
}

.sublayers img{
    border-radius:10px;
    width:100%;
}

.sublayers a{
    padding:0 !important;
    color:#fff !important;
    line-height:30px;
    transition:0.2s;
}

.sublayers a:hover{
    margin-left:5px;
    text-decoration:underline;
    transition:0.2s;
}

.sublayers h5 a {
    font-size: 20px !important;
}

.sublayers hr{
    margin: 1rem 0;
        color: inherit;
        border: 0;
        border-top: var(--bs-border-width) solid;
        opacity: 0.5 !important;
        color: white !important;
}

.cat_name{
    text-align:center;
}
/* mega menu ends */

/* LG Carousel */
.carousel-container .fade:not(.show){
    opacity:1 !important;
}

.carousel-container a{
    text-decoration:none !important;
}

.thumbnails {
    justify-content: center;
}
/* LG Carousel Ends*/

/* LG Box */
.lg-box{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    padding: 0 20px;
}

.lg-box-ft{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
}

.lg-sub-box{
    display:flex;
    flex-direction:row;
    width:33%;
    background:#d5d5d36b;
    border-radius:10px;
    padding:10px;
    transition:0.5s;
}

.lg-sub-box:hover{
    background: #cc00411c;
    transition:0.5s;
}

.lg-sub-box-ft{
        display: flex;
        flex-direction: column;
        width: 32%;
        background: transparent;
        border-radius: 10px;
        transition: 0.5s;
        position:relative;
}

.need-help{
        padding: 30px 20px 20px 20px;
            display: flex;
            flex-direction: column;
            border-top:solid thin #c1bbbb;
            margin-top:50px;
            background:#f1f1f1;
}

.need-help-subtitle {
    font-size: 24px;
}

.need-help-first-row{
        display: flex;
            flex-direction: row;
            justify-content: space-between;
}

.need-help-first-row-first-col{
    display:flex;
    flex-direction: column;
}

.phone-numbers-search-container{

}

.phone-number-search-box{
    width:50%;
    margin-bottom:10px;
    border-radius:10px;
    padding:10px;
}

.dropdown_direct_menu{
    padding:0 !important;
}

.latest-product-title-and-button{
    color: #fff;
        position: absolute;
        bottom: 30px;
        left: 30px;
        
        width: 50%;
        display:flex;
        flex-direction: column;
}

.latest-product-title{
    font-size: 40px;
}

.lg-sub-box-ft-description{
    font-size:16px;
}

.lg-sub-box-ft img{
    border-radius:10px;
    width:100%;
}

.lg-sub-box-ft-subtitle{
    font-size: 20px;
    padding-top: 10px;
}

.lg-sub-box-ft-title{
    font-size: 30px;
    font-weight:bold;
}

.lg-super-sub-box{
    display:flex;
    flex-direction:column;
    padding:15px;
}

.lg-super-sub-box-icon{
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding:15px 0 15px 15px;
}

.lg-box-title{
    font-weight:bold;
    font-size:16px;
    color:#cc0041;
}

.lg-box-description{
    font-size:12px;
}
/* LG Box Ends */

.category-slider-icons{
    font-size:50px;
    color:#000;
}

/* LG Banner - 1 */
.lg-banner-box{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    padding: 10px 20px;
}

.lg-banner-sub-box-1{
    display:flex;
    flex-direction:row;
    width:66.55%;
    background:#f4f4f4;
    border-radius:10px;
    transition:0.5s;
}

.lg-banner-sub-box-2{
    display:flex;
    flex-direction:row;
    width:33%;
    background:#f4f4f4;
    border-radius:10px;
    transition:0.5s;
}

.lg-banner-sub-box-1 img{
    width:100%;
    border-radius:10px;
}

.lg-banner-sub-box-1:hover{
    background: #cc00411c;
    transition:0.5s;
}

.lg-banner-sub-box-2 img{
    width:100%;
    border-radius:10px;
}

.lg-banner-sub-box-2:hover{
    background: #cc00411c;
    transition:0.5s;
}

.title-and-caption{
    position:absolute;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 60px;
}

.lg-banner-sub-box-title{
    font-size:50px;
    font-weight:bold;
}

.lg-banner-sub-box-caption{
    font-size:16px;
    padding:10px 0;
}

.title-and-caption-2{
    position:absolute;
    color: #000;
    display: flex;
    flex-direction: column;
    padding: 40px;
}

.lg-banner-sub-box-title-2{
    font-size:50px;
    font-weight:bold;
}

.lg-banner-sub-box-caption-2{
    font-size:16px;
    padding:10px 0;
}

.learn-more-btn{
    width:100px;
    background:#cc0041;
    border:solid thin #cc0041;
    color:#fff;
    text-decoration:none;
    padding:5px;
    text-align:center;
    border-radius:5px;
    transition:0.2s;
    cursor:pointer;
}

.learn-more-btn:hover{
    background:#fff;
    color:#cc0041;
    transition:0.2s;
}
/* LG Banner - 1 Ends */

/* Footer */
.main_footer{
    background: #000;
    margin:0;
    color: #fff;
    padding:40px;
}

.main_footer-text{
    font-size:14px;
}

.main_footer-text_long{
    font-size:14px;
    max-width: calc(100% - 100px);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.caution-btn{
    position: relative;
    display: inline-block;
    padding: 0 12px 0 6px;
    border: 0;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    background: 0 0;
    cursor: pointer;
    text-transform: uppercase;
    white-space: nowrap;
}

.caution-btn:after{
    position:absolute;
    content:'';
    right:0;
    top:8px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 8px solid #fff;
  }

.main_footer_caution{
    display:flex;
    flex-direction: row;
}

.footer_categories{
    display: flex;
    width: 100%;
    flex-direction: row;
    padding:20px 0;
}

.footer_categories_column{
    width:20%;
}

.footer_categories_column_heading{
    font-size:18px;
    font-weight:bold;
    display:block;
    width:100%;
    margin: 10px 0;
    /* color:#cc0041; */
    color:#fff;
    letter-spacing: 2px;
}

.heading-gap{
    margin-top: 30px !important;
}

.footer_categories_column_semi_heading{
    font-size:16px;
    font-weight:bold;
    display:block;
    width:100%;
    margin-bottom:10px;
}

.footer_categories_column ul{
    padding-left:0;
}

.footer_categories_column ul li{
    font-size:16px;
    list-style: none;
    padding:10px 0;
}

.ul-sub{
    padding-left:10px !important;
}

.ul-sub li{
    color:#ffffff94 !important;
}

.bottom_footer{
    margin:0;
    background:#cc0041;
    color:#fff;
    padding:20px 40px;
    display:flex;
    flex-direction:row;
    width:100%;
    justify-content: space-between;
}

.bottom_footer_sub_box{
    display:flex;
    flex-direction:column;
}

.bottom_footer_sub_box_top ul{
    list-style:none;
    margin:0;
    padding:0;
}

.bottom_footer_sub_box_top ul li{
    position: relative;
    float: left;
    margin-right: 30px;
    line-height: 18px;
    font-size:14px;
}

.bottom_footer_sub_box_top_copyright{
    display:block;
    padding-top:20px;
    font-size:14px;
    color:#fff;
}

.bottom_footer_sub_box_top_official{
    display:block;
    font-size:14px;
    text-decoration:none;
}

.lg-jeong-do{
    display:flex;
    flex-direction:row;
    color:#000;
    padding:5px 20px 5px 5px;
    border-radius:5px;
}
/* Footer */

.saparu-route{
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.saparu-embed{
    width:100%;
    height:600px;
}

.complain-box-display{
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 33%;
    display: flex;
    flex-direction: column;
}

.complain-box-form-title{
    width:100%;
}

.complain-box-form-title-span{
    font-size: 24px;
}

/*category grid slider */
.cat-grid-slider{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    position:relative;      
}

.cat-grid-box{
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: 15px;
        border-radius: 10px;
        margin: 0 10px;
        position: relative;
        background: transparent;
}

.cat-grids{
    height:80px !important;
}

.catgrid-p-box-text{
    margin-top: 5px;
        width: 100%;
        text-align: center;
}

.glider-grid {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    margin: 0 auto;
        position: relative
}

.glider-grid,
.glider-contain {
    width:100%;
}

.grid-glider-prev{
    left:0 !important;
}

.grid-glider-next{
    right:0 !important;
}

.grid-glider-prev, .grid-glider-next{
    height:35px !important;
    width:35px !important;
    font-size:20px !important;
    border-radius:50% !important;
    background:#f1f1f1d1 !important;
    border:solid thin #cc0041 !important;
    color:#cc0041 !important;
}
/*category grid slider ends */

/* Featured Products */
.p-slider{
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 20px 50px 20px;
            border-top: solid thin #c1bbbb;
            margin-top: 50px;
            border-bottom: solid thin #c1bbbb;
            margin-bottom: 50px;
}

.our-latest-products{
    margin: 30px 20px 20px 20px;
    display:flex;
    flex-direction: column;
}

.out-latest-products-heading{
        font-size: 50px;
        font-weight: bold;
}

.featured-product-heading{
    text-align:center;
    color:#000;
    text-transform: uppercase;
    width:20%;
    font-size: 24px;
    font-weight: bold;
}

.support-btn{
    background: #fff;
        padding: 10px 20px;
        color: #000;
        text-decoration: none;
        font-size: 26px;
        border:solid thin #000;
        border-radius:30px;
        transition: 0.2s;
}

.support-btn:hover{
    background:#cc0041;
    color:#fff;
    border:solid thin #cc0041;
    transition:0.2s;
}
.login-btn{
    background: #cc0041;
        padding: 4px 10px;
        color: #fff;
        text-decoration: none;
        font-size: 22px;
        border: solid thin #cc0041;
        border-radius: 30px;
        transition: 0.2s;
        width: 130px;
        margin-top: 15px;
        text-align:center;
}

.login-btn:hover{
    background:#fff;
    color:#cc0041;
    border:solid thin #cc0041;
    transition:0.2s;
}
.register-btn{
    background: #fff;
        padding: 4px 10px;
        color: #cc0041;
        text-decoration: none;
        font-size: 22px;
        border: solid thin #cc0041;
        border-radius: 30px;
        transition: 0.2s;
        width: 130px;
        margin-top: 15px;
        text-align:center;
}

.register-btn:hover{
    background:#cc0041;
    color:#fff;
    border:solid thin #cc0041;
    transition:0.2s;
}

.contine-with-option{
    padding:10px 0;
    text-align:center;
    font-size:20px;
    position:relative;
}

.contine-with-option:before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: 2px;
    width: 100%;
    height: 1px;
    background: #979797;
}

.sign-in-with-social{
    text-align:center;
}

.sign-in-with-text{
    text-align: center;
        width: 100%;
        display: block;
        margin-bottom: 10px;
}

.sign-in-with-social span{
    margin:0 5px;
    font-size:25px;
    background:#fff;
    padding:3px 10px 10px 10px;
    border-radius:10px;
    position:relative;
}

.continue-with-option-or{
    background-color:#f0ece4;
    padding: 0 20px;
    font-size: 18px;
    position:relative;
}

.need-help-support-ul{
    list-style: none;
    margin:0 !important;
    padding:0 !important;
    display: flex;
    flex-wrap: wrap;
    column-gap: min(1.5rem, 1.6666666667%);
    row-gap: 1.5rem;
    justify-content: space-between;
}

.support-links{
    display:flex;
    flex-wrap: wrap;
    padding:25px;
    flex-direction:column;
    text-decoration: none;
        color: #000;
        justify-content: space-between;
        height:100%;
}

.support-links:hover .support-arrow svg{
    background:#cc0041;
    color:#fff;
    border-color:#cc0041;
}

.support-contents-title{
    font-size: 30px;
    line-height: 30px;
}

.support-contents-desc{
        font-size: 16px;
            line-height: 18px;
            margin-top:10px;
}

.need-help-support-ul li{
    list-style: none;
    margin:0 !important;
    align-items: center;
    border-radius: 10px;
    width: min(28.375rem, 23.75%);
    background:#fff;
    display:inline-flex;
    flex:none;
    align-content: flex-start;
    flex-wrap: wrap;
    box-shadow: 0px 0px 2px #0000001c;
}

.support-contents{
        display:flex;
        flex-direction: column;
}

.support-arrow{
    display: flex;
}

.support-contents{
    width: calc(100% - 5.25rem);
}

.support-encloser{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.support-image svg {
    width: 50px !important;
    height: 50px !important;
}

.need-help-second-row{
    margin-top:20px;
}

.support-arrow svg {
    width: 50px !important;
    height: 50px !important;
    padding:10px;
    border:solid thin black;
    border-radius:50%;
    margin-top:20px;
}

.product-box{
    display:flex;
    flex-direction: column;
    width:300px;
    padding:15px;
    border:1px solid rgba(0,0,0,0.08);
    border-radius:10px;
    margin:0 10px;
    position:relative;
    background:#fff;
}

.need-help-first-row-second-col{
    display:flex;
    align-items: center;
}

.product-box-list-page{
    display:flex;
    flex-direction: column;
    width:100%;
    padding:15px;
    border:1px solid rgba(0,0,0,0.08);
    border-radius:10px;
    margin:0 10px;
    position:relative;
    background:#fff;
}

.lg-img-container{
    width:100%;
    height:100%;
    display:flex;
    overflow:hidden;
}

.p-img a, .p-img{
    width:100%;
    height:100%; 
    display:flex;
    justify-content: center;
}

.p-img a img{
    width:100%;
    height: 300px;
    object-position: center;
    object-fit: contain;
    animation:lg-fade 0.3s;
}

.p-img-back{
    display:none;
}

.lg-img-container:hover .p-img-back{
    display:block;
}

.lg-img-container:hover .p-img-front{
    display:none;
}

@keyframes lg-fade{
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}

.p-box-text{
    margin-top:5px;
    width:100%;
}

.product-category{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap; 
}

.product-category span{
    color:#acacac;
    font-size:0.9rem;
    margin:5px 0;
}

.product-title{
    color:#333333;
    font-weight:600;
    text-decoration:none;
    transition:all ease 0.3s;
}

.product-title:hover{
    color:#cc0041;
    opacity:0.7;
    transition:all ease 0.3s;
}

.price-buy{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-top:8px;
}

.price-buy .p-price{
    font-size:1.3rem;
    color:#333333;
    font-weight:700;
}

.price-buy .p-buy-btn{
    color: #cc0041;
    margin: 0 5px;
    font-weight:600;
    text-decoration:none;
}

.price-buy .p-buy-btn:hover{
    text-decoration:underline;
}

.p-discount{
    position:absolute;
    left:30px;
    top:30px;
    width:65px;
    height:24px;
    color:#fff;
    background:#cc0041;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:0.7rem;
    letter-spacing: 1px;
    z-index:99;
    border-radius:3px;
}

.featured-title-btns{
    display:flex;
    flex-direction:row;
    width:100%;
    margin:10px 0;
}

.slider-btns{
    display:flex;
    justify-content: flex-end;
    width:100%;
    margin:30-x auto 10px auto;
    padding:0 10px;
}

.slider-btns button{
    position:static !important;
    transform:translate(0,0);
    background:#f3f3f3;
    width:80px;
    height:35px;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-right:10px;
    border:1px solid #cfcfcf;
    border-radius:4px;
}

.glider-next,
.glider-prev{
    top:50% !important;
    transform:translateY(-50%);
}

.slider-btns button span{
    font-weight:bold;
    padding:10px 15px 10px 10px;
    position: relative;
    text-decoration: none;
}

.slider-btns button span::before, .slider-btns button span::after{
    border-right:2px solid;
    display:block;
    height:8px;
    margin-top:-6px;
    position:absolute;
    transform:rotate(135deg);
    right:10px;
    top:50%;
    width:0;
    content:'';
}

.slider-btns button span::after{
    margin-top: -1px;
    transform:rotate(45deg);
}

.slider-btns .glider-prev span{
    transform: rotate(-180deg);
}
/* 
.glider-track .active{
    background:#f0ece4;
} */

.cat-grid-glide-auto-width .glider-track{
    width:auto !important;
}

.cat-page-title-and-desc{
    text-align:center;
    margin:80px 0;
}

.cat-page-title-and-desc h1{
        width: 50%;
        margin-left: 50%;
        transform: translateX(-50%);
}

.cat-page-title-and-desc p{
    width:50%;
    margin-left:50%;
    transform:translateX(-50%);
}

.mob-header{
    display:none;
}

.mob-header{
    position: fixed;
    width: 100%;
    z-index: 10000;
    top:0;
}

.mob-header .for-mobile {
    height: 84px;
}

.mob-header .row {
    border-bottom: 1px solid #e4e4e4;
    background: #fff;
}

/* .mob-header .row{
    content: " ";
    display: table;
} */

.mob-header .for-mobile .top-menu {
    width: 100%;
    height: 36px;
    overflow: hidden;
    background: #d9d9d9;
    padding: 4px 0 0 0;
}

.mob-header .for-mobile .top-menu ul {
    width: 100%;
    height: 32px;
}

.mob-header ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mob-header .for-mobile .top-menu ul li.active {
    background: #d9d9d9;
}

.mob-header .for-mobile .top-menu ul li {
    width: 50%;
    height: 100%;
    padding: 0 24px;
    overflow: hidden;
    background: #fff;
    float: left;
}

.mob-header .for-mobile .top-menu ul li.active a {
    color: #333;
}

.mob-header .for-mobile .top-menu ul li a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    font-weight: 600;
    color: #6b6b6b;
    background: #fff;
}

.mob-header .for-mobile .top-menu ul li a span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 2;
}

.mob-header .for-mobile .top-menu ul li.active:nth-child(1) a:after {
    bottom: auto;
    top: 0;
    right: -28px;
}

.mob-header .for-mobile .top-menu ul li:nth-child(1) a:after {
    right: -28px;
}

.mob-header .for-mobile .top-menu ul li.active a:after {
    background: #fff;
}

.mob-header .for-mobile .top-menu ul li a:after {
    content: "";
    position: absolute;
    display: block;
    width: 200%;
    height: 62px;
    bottom: 0;
    border-radius: 31px 31px;
    z-index: 1;
    background: #d9d9d9;
}

.mob-header .for-mobile .top-menu ul li:nth-child(2) a:after {
    left: -28px;
}

.mob-header .for-mobile .nav-wrap {
    position: relative;
    width: 100%;
    height: 47px;
}

.mob-header .for-mobile .logo {
    width: 140px;
    text-align: center;
    margin: 0 auto;
}

.mob-header .for-mobile .logo a {
    padding: 11px 20px;
    display: inline-block;
}

.mob-header .for-mobile .logo a img {
    width: auto;
    height: 22px;
}

.mob-header .for-mobile .menu {
    position: static;
    padding: 0;
    margin-top: -46px;
    float: left;
}

.mob-header .for-mobile .menu>a {
    display: block;
    width: 58px;
    height: 47px;
}

.mob-header .for-mobile .menu>a .line.line1 {
    width: 16px;
    top: 16px;
}

.mob-header .for-mobile .menu>a .line.line2 {
    width: 14px;
    top: 23px;
}

.mob-header .for-mobile .menu>a .line.line3 {
    width: 18px;
    top: 30px;
}

.mob-header .for-mobile .menu>a .line {
    display: block;
    height: 2px;
    background: #333;
    position: absolute;
    left: 20px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

.mob-header .for-mobile .menu .menu-wrap {
    display: none;
    width: 100%;
    background: #e9e9e9;
    border-bottom: 10px solid #cc0041;
    position: fixed;
    top: 84px;
    left: 0;
    z-index:999;
    height:100%;
    animation:menuappears 0.5s forwards;
}

@keyframes menuappears{
    from{
        transform: rotateX(90deg);
    }
    to{
        left:0;
        transform: rotateX(0deg);
    }
}

.mob-header .for-mobile .menu .menu-wrap .depth1-m.active {
    display: block;
    position:relative;
    z-index:9999999;
}

.mob-header .for-mobile .menu .menu-wrap .depth1-m {
    margin: 0;
    padding: 0;
}

.mob-header .for-mobile .menu .menu-wrap .depth1-m>li {
    position: relative;
    border-bottom:1px solid #c1c1c1;
}

.mob-header .for-mobile .menu .menu-wrap .depth1-m>li a {
    position: relative;
    display: block;
    width: 100%;
    font-size: 17px;
    line-height: 24px;
    padding: 13px 24px 14px;
    color: #333;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
}

.search-area .search-layer ul.list, li, ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mob-header .for-mobile .menu .menu-wrap .depth1-m>li.support {
    border-top: 1px solid #e4e4e4;
    height: 53px;
}

.mob-header .for-mobile .menu .menu-wrap .sublayer-m {
    display: none;
}

.mob-header .for-mobile .menu .menu-wrap .back {
    width: 100%;
    height: 51px;
    background: #f8f8f8;
}

.mob-header .for-mobile .menu .menu-wrap .back a {
    display: block;
    width: 100%;
    height: 51px;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m {
    margin: 0;
    padding: 0;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li:first-child.type1 {
    border-bottom: 1px solid #e4e4e4;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.type1 {
    border-top: 1px solid #e4e4e4;
    color: #333;
    text-transform: uppercase;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.type1 a {
    color: #333;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li a, .mob-header .for-mobile .menu .menu-wrap .depth2-m li span {
    position: relative;
    display: block;
    width: 100%;
    font-size: 17px;
    line-height: 24px;
    padding: 13px 24px 14px;
    color: #333;
    text-decoration: none;
    font-weight: 600;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.type2 {
    color: #333;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.type2 a, .mob-header .for-mobile .menu .menu-wrap .depth2-m li.type2 span {
    color: #333;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.type2+.type3 {
    padding-left: 5px;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.type3 li, .mob-header .for-mobile .menu .menu-wrap .depth2-m li.type3 ul {
    margin: 0;
    padding: 0;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.type3 li, .mob-header .for-mobile .menu .menu-wrap .depth2-m li.type3 ul {
    margin: 0;
    padding: 0;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.type3 a {
    position: relative;
    color: #6b6b6b;
    font-size: 16px;
    padding: 14px 24px 14px;
    font-style: normal;
    font-weight: 400;
}

.mob-header .for-mobile .menu .menu-wrap .banner {
    position: relative;
    margin: 24px 0 30px;
}

.mob-header .for-mobile .menu .menu-wrap .featured-product {
    position: relative;
}

.mob-header .for-mobile .menu .menu-wrap .column.banner+.back, .mob-header .for-mobile .menu .menu-wrap .column.featured-product+.back {
    border-top: 1px solid #e4e4e4;
}

.mob-header .for-mobile .menu .menu-wrap .back a {
    display: block;
    width: 100%;
    height: 51px;
}

.mob-header .for-mobile .menu .menu-wrap .banner a {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 265px;
    height: 300px;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.expand {
    position: relative;
    border-top: 1px solid #e4e4e4;
    color: #333;
    text-transform: uppercase;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.expand a {
    position: relative;
    color: #333;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.sub {
    display: none;
}

.mob-header .for-mobile .menu .menu-wrap .depth2-m li.sub .type3 {
    padding-left: 0!important;
}

.mob-header .for-mobile .menu .menu-wrap .mylg.active {
    display: block;
}

.mob-header .for-mobile .menu .menu-wrap .mylg {
    display: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid #e4e4e4;
}

.mob-header .for-mobile .menu .menu-wrap .mylg>li {
    position: relative;
}

.mob-header .for-mobile .menu .menu-wrap .mylg>li a {
    position: relative;
    display: block;
    width: 100%;
    font-size: 17px;
    line-height: 24px;
    padding: 13px 24px 14px;
    color: #333;
    text-decoration: none;
    font-weight: 600;
}

.mob-header .before-login {
    display: block!important;
}

.mob-header .after-login {
    display: none!important;
}

.mob-header .for-mobile .menu .menu-wrap .sublayer-m .welcome {
    color: #333;
    padding: 14px 24px 13px;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    font-weight: 600;
}

.mob-header .for-mobile .menu .menu-wrap .sublayer-m .welcome .name {
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
}

.mob-header .for-mobile .left {
    position: static;
    margin-top: -46px;
    float: left;
    padding: 0 10px 0 0;
}

.mob-header .for-mobile .left .icons .search {
    position: static;
}

.mob-header .for-mobile .left .icons>div {
    display: inline-block;
    padding: 10px 12px 0 0;
}

.mob-header .for-mobile .left .icons .search:before {
    display: block;
    width: 1px;
    height: 14px;
    content: "";
    position: absolute;
    left: 19px;
    top: 7px;
}

.mob-header .for-mobile .left .icons .search>a {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0ic3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48cGF0aCBmaWxsPSIjMWExYTFhIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjYzIDE2LjI1YTcuNjMgNy42MyAwIDEgMSA1LjM5LTEzLjAyIDcuNjI1IDcuNjI1IDAgMCAxLTUuMzkgMTMuMDJabTAtMTMuNzNhNi4xMTEgNi4xMTEgMCAxIDAgNC4zMSAxLjc5IDYuMDE5IDYuMDE5IDAgMCAwLTQuMzEtMS43OVpNMTguMjMgMTlhLjczNi43MzYgMCAwIDEtLjUzLS4yM2wtMy45Mi0zLjkxYS43NjQuNzY0IDAgMCAxIDEuMDgtMS4wOGwzLjkxIDMuOTFhLjc3Ljc3IDAgMCAxLS41NCAxLjMxWiIvPjwvc3ZnPg==) no-repeat 50% 50%;
    background-size: 20px 20px;
}

.mob-header .for-mobile .left .icons>div>a {
    width: 28px;
    height: 28px;
    display: inline-block;
}

.mob-header .for-mobile .right {
    position: static;
    margin-top: -46px;
    float: right;
    padding: 0 20px 0 0;
}

.mob-header .for-mobile .right .icons .login {
    position: static;
    padding-left: 7px;
}

.mob-header .for-mobile .right .icons>div {
    display: inline-block;
    padding: 10px 0 0 12px;
}

.mob-header .for-mobile .right .icons .login>a {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0ic3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48cGF0aCBkPSJNMTQwOC45NiA1ODE1LjU0YTQuNzcgNC43NyAwIDEgMSA0Ljc4LTQuNzcgNC43ODYgNC43ODYgMCAwIDEtNC43OCA0Ljc3Wm0wLTguMDRhMy4yNyAzLjI3IDAgMSAwIDMuMjcgMy4yNyAzLjI4IDMuMjggMCAwIDAtMy4yNy0zLjI3Wm04LjE4IDE2LjQxYS43NDguNzQ4IDAgMCAxLS43My0uNTZjLS43Ni0yLjgxLTMuODktNC44NC03LjQzLTQuODRzLTYuNjggMi4wMy03LjQ0IDQuODRhLjczOC43MzggMCAwIDEtLjkyLjUzLjc1MS43NTEgMCAwIDEtLjUzLS45MmMuOTUtMy41MSA0LjYtNS45NiA4Ljg5LTUuOTZzNy45MyAyLjQ1IDguODggNS45NmEuNzUxLjc1MSAwIDAgMS0uNTMuOTIuNjI4LjYyOCAwIDAgMS0uMTkuMDNaIiBzdHlsZT0iZmlsbDojMWExYTFhO2ZpbGwtcnVsZTpldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTM5OC45NyAtNTgwNC45NTUpIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 20px 20px;
}

.mob-header .for-mobile .right .icons>div>a {
    width: 28px;
    height: 28px;
    display: inline-block;
}

.mob-header .before-login {
    display: block!important;
}

.the-search-window {
    display: none;
    padding: 0 0;
    height: 100vh;
    overflow: auto;
    position: absolute;
    z-index: 100;
    top: 0;
    left: -15px;
    right: -15px;
    width: auto;
    background-color: #fff;
}

.mobile-carousel{
    display:block;
}



.visible-mobile{
    display:none;
    background: #000;
    margin: 0;
    color: #fff;
    padding: 40px;
}

.footer-component{
    margin-top: 20px;
}

.footer-contents::before, .visible-mobile .footer-component .footer-contents .footer-contents-box .box-wrap:last-child:before{
    content: " ";
    display: table;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box {
    display: block;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 30px;
    padding-top: 17px;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box .box-wrap:first-child .footer-title:first-child {
    margin-top: 9px;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box .footer-title {
    margin-bottom: 14px;
    font-size: 18px;
    line-height: 24px;
    color: #000;
}

.footer-component .footer-contents .footer-title .no-link, .footer-component .footer-contents .footer-title a, .footer-component .footer-contents .footer-title h5 {
    display: inline-block;
    font-size: 17px;
    line-height: 1.176471em;
    font-style: normal;
    font-weight: 700;
    color: #000;
    word-break: normal;
    word-wrap: normal;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box .support-text {
    display: block;
}

.footer-component .footer-contents .footer-contents-box .support-text {
    display: inline-block;
    vertical-align: top;
    margin-top: 0;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box .box-wrap:last-child:after{
    clear: both;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box.site-list {
    padding-top: 0;
    margin-bottom: 9px;
    border-bottom: 1px solid #e4e4e4;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box.site-list {
    padding-top: 0;
    margin-bottom: 9px;
    border-bottom: 1px solid #e4e4e4;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box:only-child {
    margin-top: -1px;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box {
    display: block;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 30px;
    padding-top: 17px;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box.site-list .link-wrap {
    border-top: 1px solid #474747;
    padding: 0;
    margin-top: -1px;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box .link-wrap {
    display: block;
    width: 100%;
    padding-right: 0;
    margin-bottom: 0;
}

.footer-component .footer-contents .footer-contents-box .link-wrap {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 50px;
}

.visible-mobile .footer-component .footer-contents .depth-1.has-category {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0ic3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48cGF0aCBkPSJtNDc3LjM4IDU4NDMuMzItNC4zNzUgNS4wNi00LjM3Ni01LjA2YS44NTguODU4IDAgMCAwLTEuMzQxIDAgMS4yMTggMS4yMTggMCAwIDAgMCAxLjU1bDUuMDMxIDUuODJhLjg5NC44OTQgMCAwIDAgMS4zNzEgMGw1LjAzMi01LjgyYTEuMjIxIDEuMjIxIDAgMCAwIDAtMS41NS44NTkuODU5IDAgMCAwLTEuMzQyIDBaIiBkYXRhLW5hbWU9IiZsdDsgY29weSA0IiBzdHlsZT0iZmlsbDojMzMzO2ZpbGwtcnVsZTpldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDYzIC01ODM3KSIvPjwvc3ZnPg==) 100% center no-repeat;
}

.footer-component .footer-contents .footer-contents-box span:not(.no-link) {
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    color: #333;
}

.visible-mobile .footer-component .footer-contents .depth-1 {
    position: relative;
    display: block;
    margin-bottom: 0!important;
    font-weight: 600;
}

.visible-mobile .footer-component .footer-contents .depth-1 .a, .visible-mobile .footer-component .footer-contents .depth-1 a {
    display: block;
    padding: 18px 0;
}

.footer-component .footer-contents a {
    font-size: 15px;
    line-height: 1.133333em;
    color: #6b6b6b;
}

.footer-component .footer-contents .footer-contents-box span:not(.no-link):last-child {
    margin-bottom: 0;
}

.visible-mobile .footer-component .footer-contents .depth-1 .a span, .visible-mobile .footer-component .footer-contents .depth-1 a span {
    font-size: 17px;
    line-height: 21px;
    color: #fff;
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: 700;
}

.visible-mobile .footer-component .footer-contents .depth-2 {
    position: relative;
    display: none;
    overflow: hidden;
    padding: 14px 0 20px;
}

.visible-mobile .footer-component .footer-contents .depth-2 .category-block {
    padding-left: 10px;
}

.visible-mobile .footer-component .footer-contents .depth-2 .category-block {
    margin-bottom: -1px;
}

.visible-mobile .footer-component .footer-contents .footer-contents-box.site-list .category-block:first-of-type .depth-category {
    border-top: 0;
}

.visible-mobile .footer-component .footer-contents .depth-2 .depth-category {
    position: relative;
    display: block;
    margin-bottom: 0;
}

.visible-mobile .footer-component .footer-contents .depth-2 .depth-category.has-category .category-button {
    background: url(/public/img/footer/accordion-expand.svg) right 4px center no-repeat;
}

.visible-mobile .footer-component .footer-contents .depth-2 .depth-category .category-button {
    font-weight: 600;
    display: block;
    padding: 16px 0 14px;
    text-decoration: none;
}

.footer-component .footer-contents a {
    font-size: 15px;
    line-height: 1.133333em;
    color: #6b6b6b;
}

.visible-mobile .footer-component .footer-contents .depth-2 .depth-category .category-button span {
    font-size: 16px;
    line-height: 1;
    color: #000;
    position: relative;
    display: inline-block;
}

.visible-mobile .footer-component .footer-contents .depth-2-list {
    position: relative;
    display: none;
    overflow: hidden;
}

.visible-mobile .footer-component .footer-contents .depth-2-list.in-depth ul {
    padding: 6px 10px 26px;
}

.visible-mobile .footer-component .footer-contents .depth-2-list.in-depth li {
    padding-left: 10px;
}

.footer-component .footer-contents ul li {
    margin-bottom: 10px;
}

.visible-mobile .footer-component .footer-contents .depth-2-list li .no-link, .visible-mobile .footer-component .footer-contents .depth-2-list li a {
    padding: 0 0 7px;
    color: #6b6b6b;
}

.visible-mobile .footer-component .footer-contents .depth-1-list li .no-link, .visible-mobile .footer-component .footer-contents .depth-1-list li a, .visible-mobile .footer-component .footer-contents .depth-2-list li .no-link, .visible-mobile .footer-component .footer-contents .depth-2-list li a {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    display: block;
}

.visible-mobile .footer-component .footer-contents .depth-1-list li span:not(.no-link), .visible-mobile .footer-component .footer-contents .depth-2-list li span:not(.no-link) {
    font-size: 16px;
    line-height: 1;
    color: #6b6b6b;
}

.divide-products-filters{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
}

.filters{
    width:20%;
}

.products{
    width:79%;
}

.shop-by-filter{
    background:#fff;
    border-radius: 10px;
    padding:10px 20px;
    margin-bottom:10px;
}

.shop-by-price{
    background:#fff;
    border-radius: 10px;
    padding:10px 20px;
    margin-bottom:10px;
    padding-bottom:20px;
}
.shop-by-capacity{
    background:#fff;
    border-radius: 10px;
    padding:10px 20px;
    margin-bottom:10px;
    padding-bottom:20px;
}

.category-grid-slider-container{
    padding: 10px 20px;
    margin:10px 20px;
    background:transparent;
    border-radius:10px;
}

.filter-heads{
    display:block;
    font-size:16px;
    font-weight:bold;
    color:#000;
    width:100%;
    border-bottom:solid medium #cc0041;
}

.filter-sub-heads{
    display:block;
    font-size:14px;
    font-weight:bold;
    color:#000;
    width:100%;
}

.shop-by-filter ul{
    padding-left:0;
    padding-top:5px;
    margin-bottom:0;
}

.shop-by-capacity ul{
    padding-left:0;
    padding-top:5px;
    margin-bottom:0;
}

.capacity-count{
    float:right;
}

.shop-by-filter ul li{
    font-size:14px;
    line-height: 25px;
}

.shop-by-capacity ul li{
    font-size:14px;
    line-height: 35px;
}

.filter-ad{
    margin-bottom:10px;
}

.filter-ad img{
    width:100%;
    border-radius:10px;
}

.lg-category-banner{
    margin:30px 0;
}

.lg-category-banner img{
    width:100%;
}

.lg-produt-grid{
    width:100%;
}

.lg-category-name{
    font-size: 28px;
    font-weight: bold;
    width: 100%;
    display: block;
    padding: 20px;
}

.lg-result-and-sort{
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px 5px 20px;
}

.sort-by-optn-dropdown{
    margin-left:10px;
        padding: 5px;
            border-radius: 5px;
}

.product-grid-list{
    padding:10px 0;
}

.shop-by-filter ul li::before{
    content: "\00BB";
    margin-right: 5px;
}

.lg-breadcrumb{
    padding:10px 10px 10px 10px;
}

.lg-breadcrumb ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
}

.lg-breadcrumb ul li{
    padding: 0 5px;
    color:#888;
}

.lg-breadcrumb ul li::after {
    content: "\27A1"; 
    color: #ccc;
    margin-left:10px;
}

.lg-breadcrumb ul li:last-child::after {
    display: none;
  }

  .price-input {
    width: 100%;
    display: flex;
    margin: 10px 0 20px;
  }
  .price-input .field {
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
  }
  .field input {
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 14px;
    margin-left: 12px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #999;
    -moz-appearance: textfield;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .price-input .separator {
    width: 130px;
    display: flex;
    font-size: 19px;
    align-items: center;
    justify-content: center;
  }
  .slider {
    height: 5px;
    position: relative;
    background: #ddd;
    border-radius: 5px;
  }
  .slider .progress {
    height: 100%;
    left: 25%;
    right: 25%;
    position: absolute;
    border-radius: 5px;
    background: #17a2b8;
  }
  .range-input {
    position: relative;
  }
  .range-input input {
    position: absolute;
    width: 100%;
    height: 5px;
    top: -5px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  input[type="range"]::-webkit-slider-thumb {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: #17a2b8;
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  }
  input[type="range"]::-moz-range-thumb {
    height: 17px;
    width: 17px;
    border: none;
    border-radius: 50%;
    background: #17a2b8;
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  }

  .for-image-and-thumbnails{
    display:flex;
    flex-direction: row;
  }

  /*product image magnify*/
  .image-container {
    display: flex;
    flex-flow: row nowrap;
    margin: 1rem;
        justify-content: space-evenly;
        background:#fff;
        padding:20px;
        border-radius:10px;
  }
  .thumbnail-container {
    /* flex: 1 1 auto; */
    width:110px !important;
  }
  .magnify-container {
    margin: 0 1rem;
    flex: 1 1 auto;
    min-width: 36vw;
    max-width:36vw;
    line-height: 1;
    height:fit-content;
  }
  .product-content-container {
        flex: none;
            width: calc(100vw - 50vw - 200px);
  }
  .thumbnails {
    display: flex;
    flex-flow: column nowrap;
  }
  .thumbnail {
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }
  .thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
    object-position: 50% 50%;
  }

  .magnify-container img {
      border-radius: 5px;
  }

  .thumbnails .thumbnail img {
      border-radius: 5px;
  }

.thumbnails{
    margin-top:0 !important;
}

        .single-product-desc {
            background: #ffffff82;
            padding: 15px;
            border-radius: 10px;
            border: solid thin #00000029;
        }
  /*product image magnify ends */

.coupon-sects{
    display:flex;
    flex-direction: row;
    gap : 5px;
}

.coupon-sects span{
    background:#333;
    color:#fff;
    padding:2px 10px;
    font-size:12px;
    border-radius:5px;
}

.single-product-desc-listings{
    border-top:solid thin #afafaf;
    display:block;
    padding:10px 0px;
}

.single-product-desc-listings:first-child {
    border-top:none;
}

  .key-features{
    width:100%;
    display:block;
    font-weight:bold;
  }

  .increment-and-buttons{
    display:block;
        border-radius: 10px;
  }

  .increment-button, .add-to-cart-button, .buy-now-button{

  }

  .counter-container{
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

  .counter-quantity{
    display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  }

  .quantity__minus,
.quantity__plus {
  display: block;
  width: 35px;
  height: 35px;
  margin: 0;
  background: #f1f1f1;
  color:#000;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
  padding-top:3px;
}

.quantity__minus span,
.quantity__plus span{
  font-size:25px;
}

.quantity__minus:hover,
.quantity__plus:hover {
  background: #cc0041;
  color: #fff;
} 

.quantity__minus {
    border-radius: 3px 0 0 3px;
  }
  .quantity__plus {
    border-radius: 0 3px 3px 0;
  }
  .quantity__input {
    width: 32px;
    height: 35px;
    margin: 0;
    padding: 0;
    text-align: center;
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    border-right: 2px solid #f1f1f1;
    background: #fff;
    color: #8184a1;
  }
  .quantity__minus:link,
  .quantity__plus:link {
    color: #8184a1;
  } 

  .addtocart{
    background: #cc0041;
    color: #fff;
    padding: 0 15px;
    height: 50px;
    width:100%;
    border: solid thin #cc0041;
    border-radius:5px;
    transition:0.2s;
        font-weight: bold;
            font-size: 17px;
  }

  .addtocart:hover{
    background: #fff;
    color: #cc0041;
    padding: 0 15px;
    height: 50px;
    border: solid thin #cc0041;
    transition:0.2s;
  }

  .wheretobuybutton{
    background: #fff;
    color: #cc0041;
    padding: 0 15px;
    height: 50px;
    width:100%;
    border: solid thin #cc0041;
    border-radius:5px;
    transition:0.2s;
        font-weight: bold;
            font-size: 17px;
  }

  .wheretobuybutton:hover{
    background: #cc0041;
    color: #fff;
    padding: 0 15px;
    height: 50px;
    border: solid thin #cc0041;
    transition:0.2s;
  }

  .scrollable-addtocart{
    background:#f8f8f8;
    border-radius:10px;
    box-shadow: 0 0 5px #bcbcbc;
  }

.pricing-addtocart{
    font-weight:bold;
    color:#cc0041;
}

.pricing-addtocart-total-title{
    font-weight:bold;
}

  .buynow{
    background: #4c9500;
    color: #fff;
    padding: 0 15px;
    height: 35px;
    border: solid thin #4c9500;
    border-radius:5px;
    transition:0.2s;
  }

  .buynow:hover{
    background: #fff;
    color: #4c9500;
    padding: 0 15px;
    height: 35px;
    border: solid thin #4c9500;
    transition:0.2s;
  }

  .price-section{
    font-size:30px;
    padding:20px;
    margin-bottom:0 !important;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }

.compare-and-wishlists-and-share{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.compare-and-wishlist span{
    font-weight:bold;
    padding: 0 15px;
    color:#000;
    cursor:pointer;
}

.compare-and-wishlist span:hover{
    color:#cc0041;
}

.comparewishlist{
    margin-top:-3px;
    margin-right:5px;
}

.share-product{
    font-weight:bold;
    font-size: 20px;
}

.share-product span{
    margin-left:10px;
}

.share-product span:hover{
    color:#cc0041;
    cursor:pointer;
}

.product-key-features{
    border: none;
    border-radius: 10px;
    padding: 20px;
}

.write-a-review{
    color:#cc0041;
    text-decoration:underline;
    margin-left:5px;
}

.review-star{
    color:#ffb400;
    margin-top: -3px;
    margin-right: 5px;
}

.product-size-select-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 10px;
}

.product-size-select-button{
    border-radius:10px;
    padding:10px 15px;
    border:solid thin #0000004d;
    background:#fff;
    width:49%;
    margin-top:10px;
}

.product-size-select-button:hover {
    border: solid thin #cc0041;
}

.single-product-name{
    font-weight:bold;
    font-size:30px;
}

.product-desc-spec{
    margin:30px 20px 20px 20px;
    border-top: solid thin #c1bbbb;
        padding-top: 20px;
}

.react-tabs__tab-list{
    margin:0 !important;
    font-size:18px !important;
    font-weight:bold !important;
}

.react-tabs__tab--selected{
    background:#cc0041 !important;
    color: #fff !important;
    border-bottom:solid 2px #cc0041 !important;
}

.react-tabs__tab-list{
    border-bottom:solid 2px #cc0041 !important;
}

.react-tabs__tab-panel{
    border-top:none !important;
    padding:15px !important;
    background:#f1f1f1 !important;
    border-radius: 0 0 10px 10px !important;
}

.react-tabs__tab-panel p {
    font-size:16px !important;
}

.react-tabs__tab-panel p table{
            border-collapse: collapse;
            border-spacing: 0;
            border-color: black;
}

.react-tabs__tab-panel p table tbody tr td{
            padding: 8px 15px;
                border: 1px solid #d3d3d3;
                width: 50%;
                font-size: 13px;
}

.react-tabs__tab-panel p table tbody tr:hover {
    padding: 8px 15px;
    background: #fff;
    color: #cc0041;
    border: 1px solid #d3d3d3;
    font-weight:bold;
    width: 50%;
    font-size: 13px;
}

.link-pointer{
    cursor:pointer;
}

.gototopbtn{
    position:fixed;
    bottom:20px;
    right:20px;
    background:#cc0041;
    width:50px;
    height:50px;
    border-radius:50%;
    display:flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    box-shadow: 0px 2px 6px #00000085;
    z-index: 999;
    border: solid medium #cc0041;
    transition:0.2s;
    cursor:pointer;
    display:none;
}

.gototopbtn:hover {
    background:#fff;
    color:#cc0041;
    transition: 0.2s;
}

.ft-prod-story-container{
    margin: 30px 20px 20px 20px;
    text-align:center;
}

.ft-prod-story{
    font-size:50px;
    font-weight:bold;
}

.product-desc-spec .react-tabs__tab--selected {
    background: transparent !important;
    border:none !important;
    color: #fff !important;
    background: #cc0041 !important;
    border-radius: 5px !important;
}

.product-desc-spec .react-tabs__tab-list{
    border-bottom:none !important;
    text-align:center;
}

.product-desc-spec .react-tabs__tab-panel p {
    margin:35px 0;
    position:relative;
}

.product-desc-spec .react-tabs__tab-panel p img{
    width:100%;
    border-radius:10px;
}

.product-desc-spec .react-tabs__tab-panel {
    border-top: none !important;
    padding: 0 !important;
    background: transparent !important;
    border-radius: 0 0 10px 10px !important;
}

.product-desc-spec .react-tabs__tab{
    font-size:20px;
    margin-right: 20px;
}

.producty-story{
        position: absolute;
        top: 100px;
        color: #fff;
        left: 100px;
        display:flex;
        flex-direction: column;
}

.producty-story-title{
    font-size:60px;
    font-weight:bold;
}

.producty-story-button{
    padding: 25px 0;
}

.producty-story-description{
    font-size:30px;
}

.giverightmargin{
    margin-right:10px;
}

.mobile-carousel .box{
    height:531px;
}

.mobile-carousel .carousel-container{
    height:auto !important;
}

.mobile-carousel .prev, .mobile-carousel .next{
    display:none !important;
}

/*register page*/
.register-sign-up-text{
    font-weight:bold;
    text-align:center;
}
.register-container{
    padding:20px;
}
.registration-box{
    margin:20px 150px;
    background:#fff;
    border-radius:20px;
    padding:20px;
}

.input-two-columns{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap:10px;
}

.email-verification-btn{
    background: #cc0041;
        color: #fff;
        text-decoration: none;
        font-size: 22px;
        border: solid thin #cc0041;
        border-radius: 30px;
        transition: 0.2s;
        width: 130px;
}

.email-verification-btn:hover{
    background:#fff;
    border:solid thin #cc0041;
    color:#cc0041;
}

.input-captions{
    font-size:14px;
    color:#9d9d9d;
    margin-bottom: 10px;
}

.lg-email-input{
    width:100%;
}

/*register page ends*/

/* cart page starts */
.cart-container{
    padding:20px;
    display:flex;
    flex-direction: row;
    gap:10px;
    justify-content: space-between;
}

.lg-cart-products{
    padding:20px;
    display:flex;
    flex-direction: row;
    width:100%;
    background: #fff;
    border-radius: 10px;
    margin-top:20px;
    gap:10px;
    justify-content: space-between;
}

.lg-cart-products:first-child {
    margin-top:0 !important;
}

.lg-cart-product-container{
    width:70%;
}

.lg-cart-product-price{
    width:20%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.cart-increment{
    flex-direction: row-reverse !important;
}

.cart-page-pricing{
    font-size:20px;
    color:#cc0041;
    font-weight:bold;
    text-align: right;
}

.lg-cart-summary{
    padding: 20px;
        background: #fff;
        border-radius: 10px;
        width:30%;
        display:flex;
        flex-direction: column;
        height: fit-content;
}

.lg-cart-summary-row-divide{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.lg-cart-summary-row-divide span{
    font-size:18px;
}

.cart-summary-total span{
    font-weight:bold;
    font-size:22px !important;
}

.lg-cart-product-model{
    color:#cc0041;
    font-weight:bold;
}

.cart-heading{
    margin:20px 20px 0 20px;
    display:block;
    text-align:center;
    font-size:32px;
    font-weight:bold;
}

.lg-cart-product-information{
    display:flex;
    flex-direction: column;
    width:65%;
}

.lg-cart-product-image{
    width:12%;
}

.lg-cart-image{
    width:100%;
}

.lg-cart-whole-container{
    margin:20px;
}
/* cart page ends */

/* checkout page starts */
.lg-checkingout{
    margin:20px;
    display:flex;
    flex-direction: row;
    gap:10px;
}

.checkout-form-container{
    background:#fff;
    padding:20px;
    border-radius:10px;
    width:70%;
}

.payment-options{
    display:flex;
    flex-direction: column;
}

.checkout-summary{
    width:30%;
    background:#fff;
    padding:20px;
    border-radius:10px;
    display:flex;
    flex-direction: column;
        height: fit-content;
}

.payment-options span{
    font-size:18px;
}

.paymentradio{
    margin-right:5px;
}
/* checkout page ends */

/* user dashboard starts */
.my-lg-dashboard{
    margin:10px 20px;
    display:flex;
    flex-direction: row;
    gap:10px;
}

.my-lg-sidebar{
    background:#fff;
    border-radius:10px;
    width:20%;
    height: fit-content;
}

.my-lg-contentbar{
    width:80%;
}

.my-lg-sidebar-navigation-text{
    display:block;
    text-align:center;
    background:#cc0041;
    padding:10px;
    color:#fff;
    font-weight:bold;
    border-radius:10px 10px 0 0;
    font-size:18px;
}

.my-lg-sidebar-navigations{
    padding-left:0;
    margin:10px 20px;
}

.my-lg-sidebar-navigations li{
    padding:5px;
    transition:0.2s;
    cursor:pointer;
    font-size:16px;
    
}

.my-lg-sidebar-navigations li:hover {
    padding: 5px 5px 5px 10px;
    transition: 0.2s;
    color:#cc0041;
    font-weight:bold;
}

.my-lg-dashboard-grids{
    display:flex;
    flex-direction: row;
    gap:10px;
}

.my-lg-dashboard-grid-box{
    background:#fff;
    border-radius:10px;
    padding:20px;
    width:25%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.my-lg-dashboard-grid-box-titles{
    display:flex;
    flex-direction: column;
}

.my-lg-dashboard-grid-icon{
    font-size:3rem;
    line-height:3.5rem;
}
.my-lg-dashboard-grid-icon svg{
    vertical-align: top;
}

.my-lg-dashboard-grid-counter{
    font-size:3.2rem;
    color:#cc0041;
    font-weight:bold;
    line-height:3.2rem;
}

.my-lg-dashboard-grid-title{
    font-weight:bold;
    font-size:16px;
}

.welcome-user-text{
    margin:0 20px;
    padding:20px;
    font-size:20px;
    
}
/* user dashboard ends */

/* blog starts */
    .blog-banner{
        padding:0 20px;
        position:relative;
    }

    .blog-banner-img{
        width:100%;
        border-radius:10px;
    }

    .blog-banner-title-and-date{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align:center;
    }

    .blog-banner-title{
        color:#fff;
        font-size:60px;
        text-align:center;
        line-height:65px;
        font-weight:bold;
    }

    .blog-banner-date{
        color:#fff;
        font-size:20px;
        font-weight:bold;
    }

    .blog-content-wrapper{
        margin:20px 100px;
        background:#fff;
        border-radius:10px;
    }

    .blog-content{
        padding:45px;
    }

    .blog-shares{
        display:flex;
        margin:20px 100px;
        justify-content:flex-end;
        align-items:center;
        font-weight:bold;
    }

    .icon-alignment{
            margin-top: -3px;
                margin-right: 5px;
    }
/* blog ends */

/* Magazine starts */
.magazine-banner{
    margin:20px;
}

.magazine-banner-img{
    width:100%;
    border-radius:10px;
}

.trend-topics-wrapper{
    margin:20px;
    background:transparent;
    border-radius:10px;
    padding:20px;
}

.trend-topics-title{
    margin:30px;
}

.trend-topics-title h3{
    text-align:center;
    font-weight:bold;
}

.trend-topics-hashtags{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.trend-topics-hashtags span{
    padding:20px 45px;
    font-size:20px;
}

.trend-topics-hashtags span:hover{
    text-decoration:underline;
    cursor:pointer;
}

.trend-topics-hashtags span::after {
    content: "\27A4";
    color: #ccc;
    margin-left: 5px;
    font-size:15px;
}

.trend-topics-hashtags span:hover::after{
    color:#cc0041;
    text-decoration:none !important;
}

.magazine-search-wrapper{
    margin:20px;
    background:#fff;
    border-radius:10px;
    padding:20px;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    column-gap:20px;
    width:55%;
    margin-left:50%;
    transform:translateX(-50%);
}

.magazine-search-title{
    font-size:20px;
}

.magazine-search-form{
    border: solid thin black;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding:10px;
    border-radius:5px;
}

.magazine-search-box-input-text{
    border:none;
    width:400px;
}

.magazine-search-box-search-btn{
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    cursor: pointer;
    opacity: 0.7;
}

.magazine-search-box-search-btn:hover {
    opacity: 1;
    color:#cc0041;
}

.blog-grids-wrapper{
    margin:20px;
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    column-gap:1%;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.blog-grid{
    background:#fff;
    border-radius:10px;
    padding:20px;
    width:32%;
    display:flex;
    flex-direction: column;
}

.blog-grid-thumbnail-wrapper{
    margin-bottom:10px;
}

.blog-grid-thumbnail{
    width:100%;
    border-radius:10px;
}

.blog-grid-date-wrapper{
    margin-bottom:10px;
}

.blog-grid-date-text{
    font-weight:bold;
    font-size:16px;
}

.blog-grid-tag{
    font-size:20px;
    font-weight:bold;
    color:#cc0041;
    margin-bottom: 20px;
}

.blog-grid-learn-more-wrapper{
    margin:20px 0;
}
/* Magazine ends */

@media only screen and (max-width: 1172px) {
    .mob-header {
            display: block;
            overflow: hidden;
        }
    .mainhead{
        display:none;
    }
}


@media screen and (orientation: portrait){
    .blog-grids-wrapper{
        margin:100px 20px 20px 20px;
    }

    .cat-page-title-and-desc{
        margin-top:140px;
        margin-bottom:10px;
    }

    .cat-page-title-and-desc h1{
        width:100%;
    }

    .phone-number-search-box{
        width:80%;
    }

    .lg-form-address-divide {
            display: flex;
            column-gap: 20px;
            flex-direction: column;
            justify-content: space-between;
        }

    .glider-next,
        .glider-prev {
            top: 50% !important;
            transform: translateY(-50%) !important;
        }

    .my-lg-dashboard-grid-box{
        width:100%
    }
    
    .my-lg-dashboard-grids{
        flex-direction: column;
    }

    .my-lg-contentbar{
        width:100%;
    }

    .welcome-user-text{
        display:block;
        text-align:center;
    }

    .my-lg-dashboard{
        flex-direction: column;
    }

    .my-lg-sidebar{
        width:100%;
    }

    .checkout-summary{
        width:100%;
    }

    .checkout-form-container{
        width:100%;
    }
    .lg-checkingout{
        flex-direction: column-reverse;
    }

    .lg-cart-product-price {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top:10px;
        }
    .lg-cart-product-information{
        width:100%;
    }
    .lg-cart-image {
            width: 50%;
        }
    .lg-cart-product-image {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    .lg-cart-products{
        display:block;
    }

    .lg-cart-summary{
        width:100%;
    }
    .lg-cart-product-container{
        width: 100%;
    }
    .cart-container{
        flex-direction: column;
    }

    .registration-box{
        margin:0;
    }

    .login-container-boxes{
        width:100%;
    }

    .login-container{
        flex-direction:column;
    }

    .lg-sub-box-ft-subtitle{
        font-size:18px;
    }

    .lg-sub-box-ft-title{
        font-size:20px;
    }

    .latest-product-title {
            font-size: 28px;
        }

    .out-latest-products-heading {
            font-size: 22px;
            font-weight: 100;
            text-align: center;
            margin-bottom: 15px;
            font-weight: bold;
        }

    .lg-sub-box-ft{
        width:100%;
        margin-bottom:15px;
    }

    .lg-box-ft{
        flex-direction: column;
    }

    .support-contents{
        width:100%;
    }

    .support-btn {
            background: #fff;
            padding: 8px 18px;
            color: #000;
            text-decoration: none;
            font-size: 18px;
            border: solid thin #000;
            border-radius: 30px;
            transition: 0.2s;
            margin-top: 10px;
        }

    .need-help-first-row{
        flex-direction:column;
    }

    .support-contents-title{
        font-size:24px;
    }

    .support-encloser{
        flex-direction: column;
    }

    .support-image{
        margin-top:15px;
    }

    .support-contents-desc, .support-arrow{
        display:none;
    }

    .need-help-support-ul li{
        width:calc(50% - .3125rem);
    }
    .producty-story-description {
            font-size: 16px;
        }

    .producty-story-title {
            font-size: 24px;
            font-weight: bold;
        }

    .producty-story{
        top:15px;
        left:20px;
    }

    .product-desc-spec .react-tabs__tab {
            font-size: 14px;
            margin-right: 0px;
        }
    
    .ft-prod-story {
            font-size: 22px;
            font-weight: bold;
        }

    .featured-product-heading{
        font-size:18px;
        width:70%;
    }

        .p-img {
            height: auto !important;
        }

    .compare-and-wishlists-and-share{
        flex-direction:column;
        align-items: center;
    }

    .compare-and-wishlists-and-share span{
        padding: 0 5px;
    }

    .compare-and-wishlist{
        border-bottom:solid thin #cc0041;
        padding-bottom:5px;
        margin-bottom:5px;
    }

    .share-product{
        text-align: center;
    }

    .image-container{
        display:flex;
        flex-direction: column-reverse;
    }

    .thumbnails{
        flex-direction: row;
    }

    .divide-products-filters{
        flex-direction: column;
    }

    .filters{
        width:100%;
    }

    .products{
        width:100%;
        margin-top:10px;
    }

    .lg-jeong-do {
        justify-content: space-evenly;
    }

    .bottom_footer{
        flex-direction: column;
    }

    .bottom_footer_sub_box_top ul li {
        line-height: 30px;
    }

    .bottom_footer_sub_box_top_copyright {
        padding-bottom: 20px;
    }

    .bottom_footer_sub_box_top_official {
        padding-bottom: 20px;
    }

    .visible-mobile{
        display:block;
    }
    .main_footer{
        display:none;
    }
    .learn-more-btn{
        font-size:12px;
    }
    .desktop-carousel{
        display:block;
    }

    .mobile-carousel{
        display:block;
        margin-top:100px;
    }

    .mainhead{
        display:none;
    }

    .lg-box{
        flex-direction: column;
    }

    .lg-sub-box{
        width:100%;
        margin: 5px 0;
    }

    .lg-banner-box{
        flex-direction: column;
    }

    .lg-banner-sub-box-1{
        width:100%;
    }

    .lg-banner-sub-box-2{
        width:100%;
        margin-top:5px;
    }

    .lg-banner-sub-box-title{
        font-size:20px;
    }

    .lg-banner-sub-box-caption{
        font-size:10px;
    }

    .title-and-caption{
        padding:15px;
    }

    .lg-banner-sub-box-title-2{
        font-size:20px;
    }

    .lg-banner-sub-box-caption-2{
        font-size:10px;
    }

    .title-and-caption-2{
        padding:15px;
    }

    .footer_categories{
        flex-direction: column;
    }

    /*mobile header */
    .mob-header{
        display:block;
        overflow:hidden;
        z-index:99999;
    }
    /*mobile header ends */
}

@media(max-width:500px){
    .product-box{
        margin:0 10px;
    }
    .p-slider{
        width:100%;
    }
    .product-slider-heading{
        text-align:center;
        font-size:1.3rem;
    }
    .p-img a, .p-img{
        height:auto;
    }
    .price-buy .p-price{
        font-size:1.1rem;
    }
    .lg-img-container:hover .p-img-back{
        display:none;
    }
    .lg-img-container:hover .p-img-front{
        display:block;
    }
}
/* Featured Products */

